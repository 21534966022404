@font-face {
  font-family: Sohne;
  src: local(Sohne), url(./assets/fonts/SohneKraftig.eot) format('opentype');
  src: local(Sohne), url(./assets/fonts/SohneKraftig.eot?#iefix) format('embedded-opentype');
  src: local(Sohne), url(./assets/fonts/SohneKraftig.woff) format('woff');
  src: local(Sohne), url(./assets/fonts/SohneKraftig.woff2) format('woff2');
  src: local(Sohne), url(./assets/fonts/SohneKraftig.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Sohne;
  src: local(Sohne), url(./assets/fonts/SohneKraftigKursiv.eot) format('opentype');
  src: local(Sohne), url(./assets/fonts/SohneKraftigKursiv.eot?#iefix) format('embedded-opentype');
  src: local(Sohne), url(./assets/fonts/SohneKraftigKursiv.woff) format('woff');
  src: local(Sohne), url(./assets/fonts/SohneKraftigKursiv.woff2) format('woff2');
  src: local(Sohne), url(./assets/fonts/SohneKraftigKursiv.ttf) format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Sohne;
  src: local(Sohne), url(./assets/fonts/SohneLeicht.eot) format('opentype');
  src: local(Sohne), url(./assets/fonts/SohneLeicht.eot?#iefix) format('embedded-opentype');
  src: local(Sohne), url(./assets/fonts/SohneLeicht.woff) format('woff');
  src: local(Sohne), url(./assets/fonts/SohneLeicht.woff2) format('woff2');
  src: local(Sohne), url(./assets/fonts/SohneLeicht.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Sohne;
  src: local(Sohne), url(./assets/fonts/SohneLeichtKursiv.eot) format('opentype');
  src: local(Sohne), url(./assets/fonts/SohneLeichtKursiv.eot?#iefix) format('embedded-opentype');
  src: local(Sohne), url(./assets/fonts/SohneLeichtKursiv.woff) format('woff');
  src: local(Sohne), url(./assets/fonts/SohneLeichtKursiv.woff2) format('woff2');
  src: local(Sohne), url(./assets/fonts/SohneLeichtKursiv.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Sohne;
  src: local(Sohne), url(./assets/fonts/SohneDreiviertelfett.eot) format('opentype');
  src: local(Sohne), url(./assets/fonts/SohneDreiviertelfett.eot?#iefix) format('embedded-opentype');
  src: local(Sohne), url(./assets/fonts/SohneDreiviertelfett.woff) format('woff');
  src: local(Sohne), url(./assets/fonts/SohneDreiviertelfett.woff2) format('woff2');
  src: local(Sohne), url(./assets/fonts/SohneDreiviertelfett.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Sohne;
  src: local(Sohne), url(./assets/fonts/SohneDreiviertelfettKursiv.eot) format('opentype');
  src: local(Sohne), url(./assets/fonts/SohneDreiviertelfettKursiv.eot?#iefix) format('embedded-opentype');
  src: local(Sohne), url(./assets/fonts/SohneDreiviertelfettKursiv.woff) format('woff');
  src: local(Sohne), url(./assets/fonts/SohneDreiviertelfettKursiv.woff2) format('woff2');
  src: local(Sohne), url(./assets/fonts/SohneDreiviertelfettKursiv.ttf) format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Sohne;
  src: local(Sohne), url(./assets/fonts/SohneFett.eot) format('opentype');
  src: local(Sohne), url(./assets/fonts/SohneFett.eot?#iefix) format('embedded-opentype');
  src: local(Sohne), url(./assets/fonts/SohneFett.woff) format('woff');
  src: local(Sohne), url(./assets/fonts/SohneFett.woff2) format('woff2');
  src: local(Sohne), url(./assets/fonts/SohneFett.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Sohne;
  src: local(Sohne), url(./assets/fonts/SohneFettKursiv.eot) format('opentype');
  src: local(Sohne), url(./assets/fonts/SohneFettKursiv.eot?#iefix) format('embedded-opentype');
  src: local(Sohne), url(./assets/fonts/SohneFettKursiv.woff) format('woff');
  src: local(Sohne), url(./assets/fonts/SohneFettKursiv.woff2) format('woff2');
  src: local(Sohne), url(./assets/fonts/SohneFettKursiv.ttf) format('truetype');
  font-weight: 900;
  font-style: italic;
}
